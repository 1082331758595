import React, { useContext } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { StoreContext } from "../context/StoreContext"

const CartPage = props => {
  const { checkout, removeProductFromCart } = useContext(StoreContext)

  const lineItems = checkout.lineItems

  console.log("fff", checkout)

  console.log("fff", lineItems)
  console.log("fff", "br")

  let someDate = new Date()
  someDate.setDate(someDate.getDate() + 8)

  let dd = someDate.getDate()
  let mm = someDate.getMonth() + 1
  let y = someDate.getFullYear()

  const startDate = mm + "/" + dd + "/" + y

  someDate = new Date()
  someDate.setDate(someDate.getDate() + 13)

  dd = someDate.getDate()
  mm = someDate.getMonth() + 1
  y = someDate.getFullYear()

  const endDate = mm + "/" + dd + "/" + y

  const getSelectedOptions = item => {
    const {
      variant: { selectedOptions },
    } = item

    return selectedOptions.map(
      option =>
        option.value !== "Default Title" && (
          <p key={option.name}>
            <small>
              {option.name}: {option.value}
            </small>
          </p>
        )
    )
  }
  return (
    <Layout>
      <SEO title="Cart" />
      <section className="section">
        {checkout.webUrl ? (
          lineItems.length === 0 ? (
            <div className="container">
              <h1 className="is-size-1 has-text-centered">
                Your cart is empty
              </h1>
              <h3 className="has-text-centered">
                <div className="columns is-centered has-text-centered is-gapless">
                  <div className="column is-4" style={{ marginTop: 30 }}>
                    <Link to="/product/personalized-pet-pillows">
                      <button className="button is-primary is-large">
                        THROW PILLOWS
                      </button>
                    </Link>
                  </div>
                  <div className="column is-4" style={{ marginTop: 30 }}>
                    <Link to="/product/personalized-pet-sequin-pillows">
                      <button className="button is-primary is-large">
                        SEQUIN PILLOWS
                      </button>
                    </Link>
                  </div>
                </div>
              </h3>
            </div>
          ) : (
            <div className="container">
              <h1 className="is-size-1 has-text-centered has-text-weight-bold">
                YOUR CART
              </h1>

              <hr />

              <div className="box">
                <div className="columns is-centered is-vcentered is-hidden-mobile">
                  <div className="column is-2"></div>
                  <div className="column is-5">
                    <p>
                      <strong>
                        <u>Product Name</u>
                      </strong>
                    </p>
                  </div>
                  <div className="column is-2">
                    <strong>
                      <u>Quantity</u>
                    </strong>
                  </div>
                  <div className="column is-2">
                    <strong>
                      <u>Price</u>
                    </strong>
                  </div>
                </div>

                {lineItems.map(item => (
                  <div
                    key={item.id}
                    className="columns is-centered is-vcentered"
                  >
                    <div className="column is-2">
                      <img src={item.variant.image.src} />
                    </div>
                    <div className="column is-5">
                      <p>
                        <strong>{item.title}</strong>
                      </p>
                      {getSelectedOptions(item)}
                      {item.customAttributes.length > 0 && (
                        <>
                          <Link
                            to={`/design/${
                              item.customAttributes.find(
                                atr => atr.key === "_handle"
                              ).value
                            }`}
                            state={{
                              edit: true,
                              customAttributes: item.customAttributes,
                              selectedOptions: item.variant.selectedOptions,
                              lineItemId: item.id,
                            }}
                          >
                            <button
                              className="is-small button is-default is-outlined"
                              style={{ marginBottom: 10 }}
                            >
                              Edit
                            </button>
                          </Link>
                          <br />
                        </>
                      )}
                      <button
                        className="is-small button is-danger is-outlined"
                        onClick={() => removeProductFromCart(item.id)}
                      >
                        Remove
                      </button>
                    </div>
                    <div className="column is-2">
                      <strong>
                        <span className="is-hidden-desktop">Quantity: </span>{" "}
                        {item.quantity}
                      </strong>
                    </div>
                    <div className="column is-2">
                      <strong>
                        <span className="is-hidden-desktop">Price: </span>
                        {item.discountAllocations.length > 0 ? (
                          <>
                            <small>
                              <strike>
                                $
                                {Number(
                                  item.variant.price * item.quantity
                                ).toFixed(2)}
                              </strike>
                            </small>{" "}
                            <span style={{ color: "green" }}>
                              $
                              {Number(
                                item.variant.price * item.quantity -
                                  item.discountAllocations[0].allocatedAmount
                                    .amount
                              ).toFixed(2)}
                            </span>
                          </>
                        ) : (
                          <span>${item.variant.price * item.quantity}</span>
                        )}
                      </strong>
                    </div>
                    <hr />
                  </div>
                ))}
                <hr />
                <div className="columns is-centered is-vcentered">
                  <div className="column is-7"></div>
                  <div className="column is-2">
                    <strong>Subtotal</strong>
                  </div>
                  <div className="column is-2">
                    <strong style={{ color: "green" }}>
                      ${checkout.subtotalPrice}
                    </strong>
                  </div>
                </div>
                <div className="checkout-section">
                  <div className="columns is-centered is-vcentered">
                    <div className="column is-8-desktop">
                      <button
                        className="button is-primary is-large is-fullwidth has-text-weight-bold"
                        onClick={() => {
                          if (process.env.NODE_ENV === "production") {
                            if (window.fbq) {
                              window.fbq("track", "InitiateCheckout", {
                                value: checkout.subtotalPrice,
                                currency: "USD",
                              })
                            }

                            if (window.snaptr) {
                              window.snaptr("track", "START_CHECKOUT", {
                                price: checkout.subtotalPrice,
                                currency: "USD",
                              })
                            }

                            if (window._learnq) {
                              const klaviyoCart = {
                                $event_id: `${checkout.id}_${+new Date()}`,
                                $value: parseFloat(checkout.totalPrice),
                                ItemNames: checkout.lineItems.map(
                                  item => item.title
                                ),
                                CheckoutURL: checkout.webUrl,
                                Items: checkout.lineItems.map(item => {
                                  let productUrl = ""
                                  if (item.customAttributes.length > 0) {
                                    const handle = item.customAttributes.find(
                                      atr => atr.key === "_handle"
                                    ).value

                                    productUrl = `https://waggtopia.com/product/${handle}`
                                  }

                                  return {
                                    ProductID: item.variant.product.id,
                                    SKU: item.variant.sku,
                                    ProductName: item.title,
                                    Quantity: item.quantity,
                                    ItemPrice: parseFloat(item.variant.price),
                                    RowTotal:
                                      parseFloat(item.variant.price) *
                                      item.quantity,
                                    ProductURL: productUrl,
                                    ImageURL: item.variant.image.src,
                                  }
                                }),
                              }

                              window._learnq.push([
                                "track",
                                "Started Checkout",
                                klaviyoCart,
                              ])
                            }
                          }

                          window.location = checkout.webUrl
                        }}
                      >
                        CHECKOUT
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                {/* <div
                    className="columns is-vcentered has-text-centered is-multiline"
                    style={{ marginTop: 30 }}
                  >
                    <div className="column is-12">
                      <small className="has-text-centered is-size-7">
                        {`Expected arrival date: ${startDate}-${endDate}`}
                      </small>
                      <div className="column is-12">
                        <small className="has-text-centered is-size-7">{`Most orders will arrive by ${startDate}, but, you should prepare for the increased production time due to the increased volume we experience during the Holidays.`}</small>
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
          )
        ) : (
          <>
            <h1 className="is-size-1 has-text-centered">Your cart</h1>
            <progress className="progress is-large is-info" max="100">
              60%
            </progress>
          </>
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    christmas_guarantee: file(
      relativePath: { eq: "christmas_guarantee_2.png" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CartPage
